import { faClock, faEnvelope, faMapMarkerAlt, faMobile, faPhone } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import React from "react"
import styled from "styled-components"
import MapPreviewImage from "../components/images/MapPreviewImage"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { colors, mq } from "../utils/presets"

const Title = styled.h1`
  text-align: center;
  margin: 1rem auto 2rem;
  padding: 1rem 0;
  border-bottom: 3px solid ${colors.black};
  border-top: 3px solid ${colors.black};
  margin-right: 0 !important;
  padding-right: 0 !important;
  ${mq.xxl} {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
`

const Wrapper = styled.div`
  ${mq.tablet} {
    max-width: 1600px;
    margin: 0 auto;
    border-bottom: 3px solid ${colors.black};
    margin-bottom: 3rem;
    min-height: 33.5rem;
  }
`

const Topimage = styled(Img)`
  ${mq.tablet} {
    float: right;
    width: 50%;
    height: 500px;
    margin-top: 3.8rem;
  }
`
const Textwrapper = styled.div`
  padding: 1rem;
  ${mq.tablet} {
    /* width: 50%;
    flex: 1 0 auto; */
    h1 {
      text-align: left;
      border-bottom: none;
      margin-right: 1rem;
      padding-bottom: 0;
    }
    div {
      line-height: 1.5;
    }
  }
  ${mq.xxl} {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
`

const GoogleMapsWrapper = styled.div`
  height: 433px;
  flex: 0 1 auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 2rem;
  picture {
    opacity: 0.4;
  }
  p {
    font-size: 1rem;
    color: #eee;
    font-weight: bold;
    a {
      font-size: 1.1rem;
      color: white;
      font-weight: bold;
    }
  }
  .ladenButton {
    color: white;
    background: ${colors.green};
    display: inline-block;
    padding: 0.5rem 1rem 0.7rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border: none;
    outline: none;
    font-weight: bold;
    color: #333;
    &:hover,
    &:focus {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  .maptext {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    padding: 150px 1rem 0;
    background: rgba(0, 0, 0, 0.6);
    line-height: 1.5;
  }
`

const GoogleMapsIframe = styled.iframe`
  border: none;
  margin-bottom: 0;
  width: 100%;
  height: 400px;
  ${mq.tablet} {
    height: 500px;
  }
`

const Contactdata = styled.div`
  margin-bottom: 0;
  a {
    text-decoration: none;
    &:hover,
    &:focus {
      div {
        background: #ececec;
      }
    }
  }
  .firstlink {
    border-top: none;
  }
  ${mq.tablet} {
    .firstlink {
      border-top: 1px solid grey;
    }
  }
`

const Contactfield = styled.div`
  /* background: ${colors.lightgrey}; */
  border-top: 1px solid grey;
  padding: 1rem;
  margin: 0;
  width: 100%;
  display: flex;
  flex-flow: row;
  /* &:first-child {
    border-top: none;
  } */
  .faicon {
    margin-right: 1rem;
    flex: 0 1 auto;
    align-self: center;
  }
  p {
    margin-bottom: 0;
  }

  ${mq.tablet} {  
    width: 47%;
    .faicon {
      margin-right: 2rem;
    }
  }  
`

const ContactPageTemplate = ({ data }) => { 
  
  function handleClick(e) {
    e.preventDefault()
    // Create Element.remove() function if not exist
    if (!("remove" in Element.prototype)) {
      Element.prototype.remove = function() {
        if (this.parentNode) {
          this.parentNode.removeChild(this)
        }
      }
    }
    var maptext = document.getElementById("maptext")
    maptext.remove()
    var div = document.getElementById("mapsframe")
    div.firstElementChild.remove()
    var gmapiframe =
      "<iframe id='kontakt' title='Google Maps Mr. Bark' width='100%' height='433px' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2569.497672763357!2d11.60737131589697!3d49.908233434165375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a1bd72c9e4b173%3A0x6ed74a00442b6d44!2sMr.+Bark+%7C+Carlo+and+Friends!5e0!3m2!1sde!2sde!4v1557412244667!5m2!1sde!2sde' frameborder='0' allowfullscreen />"
    div.innerHTML += gmapiframe
  }
  
  return (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <Wrapper>
      <Topimage
        objectFit="cover"
        objectPosition="50% 50%"
        sizes={data.wordpressPage.acf.feat_img.localFile.childImageSharp.sizes}
        alt={data.wordpressPage.title}
      />

      <Textwrapper>
        <Title>{data.wordpressPage.title}</Title>
        <Contactdata>
          <a
            href="https://www.google.com/maps/dir/?api=1&destination=Mr.+Bark+%7C+Carlo+and+Friends"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Contactfield className="firstlink">
              <FontAwesomeIcon
                className="faicon fa-fw"
                icon={faMapMarkerAlt}
                size="lg"
              />{" "}
              Mr. Bark, Eva-Maria Volkert
              <br />
              Schlehenbergstr. 11, 95448 Bayreuth
            </Contactfield>
          </a>
          <a href={"tel:" + data.wordpressPage.acf.telefonnummer}>
            <Contactfield>
              <FontAwesomeIcon
                size="lg"
                className="faicon fa-fw"
                icon={faPhone}
              />
              Tel: {data.wordpressPage.acf.telefonnummer}
            </Contactfield>
          </a>
          <a href={"tel:" + data.wordpressPage.acf.mobile_number}>
            <Contactfield>
              <FontAwesomeIcon
                size="lg"
                className="faicon fa-fw"
                icon={faMobile}
              />
              Mobil: {data.wordpressPage.acf.mobile_number}
            </Contactfield>
          </a>
          <a href={"mailto:" + data.wordpressPage.acf.e_mail}>
            <Contactfield>
              <FontAwesomeIcon
                size="lg"
                className="faicon fa-fw"
                icon={faEnvelope}
              />{" "}
              {data.wordpressPage.acf.e_mail}
            </Contactfield>
          </a>
          <Contactfield style={{ borderBottom: "1px solid grey" }}>
            <FontAwesomeIcon
              size="lg"
              className="faicon fa-fw"
              icon={faClock}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: data.wordpressPage.acf.offnungszeiten,
              }}
            />
          </Contactfield>
        </Contactdata>

        {/* <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} /> */}
      </Textwrapper>
    </Wrapper>

    <GoogleMapsWrapper id="mapsframe">
          <MapPreviewImage />
          <div id="maptext" className="maptext">
            <p>
              Mit dem Laden der Karte akzeptieren Sie die <a
                href="https://policies.google.com/privacy"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >Datenschutzerklärung</a> von Google.
              <br />
            </p>
            <button className="ladenButton" onClick={handleClick}>
              Google Maps laden
            </button>
          </div>
        </GoogleMapsWrapper>

    {/* <GoogleMapsWrapper>
      <GoogleMapsIframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2569.497672763357!2d11.60737131589697!3d49.908233434165375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a1bd72c9e4b173%3A0x6ed74a00442b6d44!2sMr.+Bark+%7C+Carlo+and+Friends!5e0!3m2!1sde!2sde!4v1557412244667!5m2!1sde!2sde"
        frameborder="0"
        allowfullscreen
      />
    </GoogleMapsWrapper> */}
  </Layout>
)
}
export default ContactPageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      acf {
        feat_img {
          localFile {
            childImageSharp {
              sizes(maxWidth: 1200) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        anschrift
        telefonnummer
        mobile_number
        e_mail
        offnungszeiten
      }
    }
  }
`
